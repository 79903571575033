import React, { useEffect, useState } from "react"
import cn from "classnames"
import { useMedia } from "use-media"
import { Link, useNavigate, useSearchParams } from "react-router-dom"

import { hrefs, Medias, mixins, routes } from "utils"

import "./Header.scss"
import logoSrc from "assets/images/logo.png"
import { ReactComponent as Menu } from "assets/icons/menu.svg"
import { ReactComponent as Close } from "assets/icons/close.svg"
import {ConnectKitButton} from "connectkit";
import {useAccount} from "wagmi";

type Props = {
  openConnectModal: () => void
}

export const Header = ({ openConnectModal }: Props): JSX.Element => {
  const [search] = useSearchParams()
  const [sidebar, setSidebar] = useState(false)
  const { address } = useAccount();
  const isM = useMedia({ maxWidth: mixins.m })
  const navigate = useNavigate()

  const toggleSidebar = () => setSidebar(!sidebar)

  const links = [
    {
      title: "Telegram",
      href: hrefs.telegram,
    },
    {
      title: "Twitter",
      href: hrefs.twitter,
    },
  ]

  return (
    <aside
      className={cn("header", {
        show: sidebar,
      })}
    >
      <div
        className={cn("header-sidebar", {
          active: sidebar,
        })}
      >
        <div className="header-links-sidebar">
          {links.map((link, index) =>
            link.href ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={link.href}
                key={index}
                className="header-links-sidebar-item"
              >
                {link.title}
              </a>
            ) : (
              <button
                onClick={() => {
                  navigate(link.href || "")
                  setSidebar(false)
                }}
                key={index}
                className="header-links-sidebar-item"
              >
                {link.title}
              </button>
            ),
          )}
        </div>
      </div>

      <div className="header-left">
        <Link to={routes.index} className='header-left-logo-text'>
          <img src={logoSrc} alt="logo" className="header-left-logo" />
          FlexyFinance
        </Link>
        {isM && (
          <div className="header-left-other">
            <ConnectKitButton />

            <button className="header-left-btn" onClick={toggleSidebar}>
              {sidebar ? <Close /> : <Menu />}
            </button>
          </div>
        )}
      </div>
      <div className="header-right">
        {links.map((link, index) =>
          link.href ? (
            <a target="_blank" rel="noopener noreferrer" href={link.href} key={index} className="header-link">
              {link.title}
            </a>
          ) : (
            <button
              onClick={() => {
                navigate(link.href || "")
                setSidebar(false)
              }}
              key={index}
              className="header-link"
            >
              {link.title}
            </button>
          ),
        )}

        <ConnectKitButton />
      </div>
    </aside>
  )
}
