import React from "react"
import cn from "classnames"

import "./Works.scss"
import { Collapse } from "../Collapse"
import { SimpleButton } from "../SimpleButton"
import { ReactComponent as Messages } from "assets/icons/messages.svg"
import discordSrc from "assets/images/discord.webp"
import {hrefs} from "../../utils";

const data = [
  {
    text: "FlexyFinance smart-contract transactions represent the conditions of a contract between all users. Like in a typical contract, users must agree to the terms and conditions of the transaction on our website. Once agreed upon and initiated, the smart contract will operate as planned with no possibility of changing any of the previously established terms. The lowest stake is 50 USDT, and there is no maximum. Because the other users leave the implementation to the contract, you do not need to spend hours determining when you should sell or buy. This offers our users secure returns on their investments while ensuring that the value of their investment will never decrease in accordance with the conditions of the contract.",
  },
  {
    text: "Yield income paid on each and every block on Ethereum(approximately 3 seconds) from 30 days to 90 days.",
  },
  {
    text: "Minimum 0.27% daily for 90 days. to up to short term yield of 0.25% daily for 30 days.",
  },
  {
    text: "Safe, transparent, legit, decentralized never ending Levels yield to earn from. FlexyFinance only charges 4% service fee.",
  },
]

export const Works = (): JSX.Element => {
  return (
    <section id="work" className={cn("work")}>
      <h1 className="work-title">How it Works?</h1>
      <div className="work-content">
        {data.map((item, index) => (
          <div className="work-content-item" key={index}>
            {item.text}
          </div>
        ))}
      </div>
      <h1 className="work-title">FAQ</h1>
      <Collapse
        desc={`*No, because it will be locked in the contract forever until the pool benefits expire.
*Until the time limit for exploiting the pool expires.`}
        title="Can the invested pool be canceled?"
      />
      <Collapse
        desc={`*No, You invest in pool No. 1. So you only get 30 days of referral rewards.`}
        title="If I purchased Pool No.3 Investors in the No. 1 pool are recommended. Can I get 90days referral bonus?"
      />
      <Collapse
        desc={`Sustaining every project is hard, but the team are finding ways on how to make a project live, grow and sustain. Aside from building the treasury, in which will be invested into other projects and earnings will injected back into the project, the team will also do partnerships with other projects and also be accepting development work in which commissions will also be sent to the treasury and be re-invested.`}
        title="How is this sustainable?"
      />
      <Collapse
        desc={`We have as a team tried to make everything as unique as possible. Although some features may sound familiar but their is unique touch from our team. The contract is written to make it sustainable and profitable for the users.`}
        title="What makes this project unique?"
      />
      <Collapse
        desc={`Users have to at least invest 50 USDT.`}
        title="What is the minimum investment?"
      />
      <Collapse
        desc={`Currently there is no maximum limit.`}
        title="How much is the max that I can invest?"
      />
      <Collapse
        desc={`Referral income is one of our unique features that allows our user to earn bonus on referrals as well as referrals of referrals.`}
        title="What is referral income?"
      />

      <div className="work-discord">
        <div className="work-discord-title">Join our Telegram Community...</div>
        <SimpleButton
          href={hrefs.telegram}
          className="work-discord-btn"
          text={
            <div className="content">
              <Messages />
              Chat Now
            </div>
          }
        />
        <img src={discordSrc} alt="telegram" className="work-discord-img" />
      </div>
    </section>
  )
}
