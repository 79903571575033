import React, { useState } from "react"
import Web3 from "web3"

import "./Withdrawal.scss"
import { SimpleButton } from "../SimpleButton"
import abi from "abi/abi.json"
import { contractAddress } from "../../abi"
import {useAccount} from "wagmi";

type Props = {
  userWithdrawal: number
  available: number
  userDeposit: number
}

export const Withdrawal = ({ userWithdrawal, available, userDeposit }: Props): JSX.Element => {
  const { address } = useAccount();


  return (
    <div className="withdrawal-content">
      <div className="withdrawal-content-block">
        <div className="withdrawal-content-block-item">
          <div className="withdrawal-content-block-item-title">USDT</div>
          <div className="withdrawal-content-block-item-value">
            Total Deposit: {userDeposit > 0 ? userDeposit.toFixed(3) : 0}
          </div>
        </div>
        <div className="withdrawal-content-block-item">
          <div className="withdrawal-content-block-item-title">USDT</div>
          <div className="withdrawal-content-block-item-value">
            Total Withdraw: {userWithdrawal > 0 ? userWithdrawal.toFixed(3) : 0}
          </div>
        </div>
      </div>
      <div className="withdrawal-content-block">
        <div className="withdrawal-content-block-item">
          <div className="withdrawal-content-block-item-title">Available Withdraw Balance:</div>
          <div className="withdrawal-content-block-item-value">{available > 0 ? available.toFixed(3) : 0} USDT</div>
        </div>
        <div className="withdrawal-content-block-item">
        </div>
      </div>
    </div>
  )
}
