import React, { useState } from "react"

import "./Seed.scss"
import { ReactComponent as Link } from "assets/icons/link.svg"
import { copyToClipboard } from "../../utils"

type Props = {
  seedCount: number
}

export const Seed = ({ seedCount }: Props): JSX.Element => {
  return (
    <div className="referral-content">
      <div className="referral-content-title">Seed</div>
      <div className="referral-content-desc">Earn up to 15% from your referred users dividends over 10 level.</div>
      <div className="referral-content-block-item">
        <div className="referral-content-block-item-title">Seed Income:</div>
        <div className="referral-content-block-item-value">{seedCount > 0 ? seedCount.toFixed(3) : 0} USDT</div>
      </div>
    </div>
  )
}
