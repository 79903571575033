import React, { useState } from "react"

import "./Yield.scss"
import { ReactComponent as Link } from "assets/icons/link.svg"
import { copyToClipboard } from "../../utils"

type Props = {
  yieldCount: number
}

export const Yield = ({ yieldCount }: Props): JSX.Element => {
  return (
    <div className="referral-content">
      <div className="referral-content-title">Daily Profit</div>
      <div className="referral-content-desc">
        Earn 0.23% - 0.27% daily on your initial deposits and watch your earnings grow in real-time.
      </div>
      <div className="referral-content-block-item">
        <div className="referral-content-block-item-title">Daily Profit:</div>
        <div className="referral-content-block-item-value">{yieldCount > 0 ? yieldCount.toFixed(3) : 0} USDT</div>
      </div>
    </div>
  )
}
