import React, { useState } from "react"

import "./Referral.scss"
import { ReactComponent as Link } from "assets/icons/link.svg"
import { copyToClipboard } from "../../utils"
import {useAccount} from "wagmi";

type Props = {
  earnings: number
}

export const Referral = ({ earnings }: Props): JSX.Element => {
  const { address } = useAccount();


  return (
    <div className="referral-content">
      <div className="referral-content-title">Referral Statistics</div>
      <div className="referral-content-desc">Copy your link to invite new users and earn up to 8% commission over 10 levels of referrals.</div>
      <div className="referral-content-block-item">
        <div className="referral-content-block-item-title">{`${window.location.origin}?ref=${address}`}</div>
        <button
          onClick={() => copyToClipboard(`${window.location.origin}?ref=${address}`)}
          className="referral-content-block-item-btn"
        >
          <Link /> Copy
        </button>
      </div>
      <div className="referral-content-block-item">
        <div className="referral-content-block-item-title">Referral Earnings:</div>
        <div className="referral-content-block-item-value">{earnings > 0 ? earnings.toFixed(3) : 0} USDT</div>
      </div>
    </div>
  )
}
