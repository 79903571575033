import React, { useState } from "react"

import { ReactComponent as Level } from "assets/icons/award.svg"
import "./Levels.scss"
import { SimpleButton } from "../SimpleButton"

export const Levels = (): JSX.Element => {
  return (
    <div className="levels-content">
      <div className="levels-content-block-item">
        <div className="levels-content-block-item-title">
          <Level />
          <div className="levels-content-block-item-title-other">
            <div className="levels-content-block-item-title-other-main">1st Referral Level:</div>
            <div className="levels-content-block-item-title-other-desc">
              Active Referrals: <span>0</span>
            </div>
          </div>
        </div>
        <div className="levels-content-block-item-value">
          <SimpleButton text="Check Referrals" />
        </div>
      </div>
      <div className="levels-content-block-item">
        <div className="levels-content-block-item-title">
          <Level />
          <div className="levels-content-block-item-title-other">
            <div className="levels-content-block-item-title-other-main">2st Referral Level:</div>
            <div className="levels-content-block-item-title-other-desc">
              Active Referrals: <span>0</span>
            </div>
          </div>
        </div>
        <div className="levels-content-block-item-value">
          <SimpleButton text="Check Referrals" />
        </div>
      </div>
      <div className="levels-content-block-item">
        <div className="levels-content-block-item-title">
          <Level />
          <div className="levels-content-block-item-title-other">
            <div className="levels-content-block-item-title-other-main">3st Referral Level:</div>
            <div className="levels-content-block-item-title-other-desc">
              Active Referrals: <span>0</span>
            </div>
          </div>
        </div>
        <div className="levels-content-block-item-value">
          <SimpleButton text="Check Referrals" />
        </div>
      </div>
      <div className="levels-content-block-item">
        <div className="levels-content-block-item-title">
          <Level />
          <div className="levels-content-block-item-title-other">
            <div className="levels-content-block-item-title-other-main">4st Referral Level:</div>
            <div className="levels-content-block-item-title-other-desc">
              Active Referrals: <span>0</span>
            </div>
          </div>
        </div>
        <div className="levels-content-block-item-value">
          <SimpleButton text="Check Referrals" />
        </div>
      </div>
    </div>
  )
}
