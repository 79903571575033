export const hrefs = {
  facebook: "",
  instagram: "",
  twitter: "",
  youtube: "",
  discord: "",
  scan: "",
  telegram: "",
  linkedIn: "",
  reddit: "",
  medium: "",
  address: "",
  docs: "",
  audit: "",
  contract: "",
}
