import cn from "classnames"
import { useState } from "react"

import "./Input.scss"
import { ReactComponent as SearchIcon } from "assets/icons/search.svg"
import { SimpleButton } from "../SimpleButton"

type Props = {
  onChange: (value: string) => void
  value: string
  placeholder?: string
  className?: string
  append?: string
  label?: string
  type?: "text" | "number"
  variant?: "default" | "margin" | "search"
  withButton?: {
    onClick: () => void
    text: string
  }
}

export const Input = ({
  onChange,
  value,
  placeholder,
  className,
  append,
  type = "text",
  variant = "default",
  label,
  withButton,
}: Props): JSX.Element => {
  const [focused, setFocused] = useState(false)

  return (
    <div className={cn("custom-input-wrapper", className)}>
      {label && <div className="custom-input-label">{label}</div>}
      {variant === "search" && <SearchIcon className="custom-input-search" />}
      <input
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onChange={e => onChange(e.target.value)}
        value={value}
        type={type}
        placeholder={focused ? "" : placeholder}
        className={cn("custom-input", variant, {
          withAppend: append,
        })}
      />
      {withButton && (
        <SimpleButton
          text={withButton.text}
          onClick={withButton.onClick}
          disabled={!value?.length}
          className="custom-input-btn"
        />
      )}
      {append && <div className="custom-input-append">{append}</div>}
    </div>
  )
}
