import { useSearchParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { ethers } from "ethers"
import Web3 from "web3"

import { Header, HeroBlock, Works, Dashboard } from "components"
import { contractAddress, approveAddress } from "abi"
import abi from "abi/abi.json"
import abiApprove from "abi/abiApprove.json"

import "./Home.scss"

export const Home = (): JSX.Element => {
  const [search] = useSearchParams()
  const [connect, setConnect] = useState(false)
  const [approved, setApproved] = useState(0)
  const [available, setAvailable] = useState(0)
  const [earnings, setEarnings] = useState(0)
  const [totalBonus, setTotalBonus] = useState(0)
  const [seedCount, setSeedCount] = useState(0)
  const [yieldCount, setYieldCount] = useState(0)
  const [totalInvested, setTotalInvested] = useState(0)
  const [userWithdrawal, setUserWithdrawal] = useState(0)
  const [userDeposit, setUserDeposit] = useState(0)
  const busdValue = 1000000000000000000


  return (
    <div className="home">
      <Header openConnectModal={() => setConnect(true)} />
      <HeroBlock />
      <Dashboard
        yieldCount={yieldCount}
        seedCount={seedCount}
        userDeposit={userDeposit}
        totalInvested={totalInvested}
        totalBonus={totalBonus}
        earnings={earnings}
        available={available}
        userWithdrawal={userWithdrawal}
        approved={approved}
      />
      <Works />
      {/*<Footer />*/}
    </div>
  )
}
