import React, { useState } from "react"
import cn from "classnames"

import imgSrc from "assets/images/main.webp"
import "./HeroBlock.scss"
import { Timer } from "../Timer"

export const HeroBlock = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false)

  return (
    <section
      id="home"
      className={cn("main", {
        notLoaded: !loaded,
      })}
    >
      <div className="main-left">
        <h1 className="main-title">FlexyFinance Smart Contract</h1>
        <p className="main-desc">
          FlexyFinance is a stake to earn platform on the top of Ethereum Chain(USDT). FlexyFinance is a decentralized
          Dapp allowing users to earn high interest rewards on their stakes. The sustainability of project will
          ultimately bring crypto users, miners, investors under single platform to stake, refer and earn USDT with
          unlimited opportunities making use of decentralization. We plan to be the perfect yield platform which
          operates every second with endless opportunities.
        </p>
        <Timer />
      </div>
      <img onLoad={() => setLoaded(false)} alt="platform" src={imgSrc} className="main-img" />
    </section>
  )
}
