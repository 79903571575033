import React, { useState } from "react"
import cn from "classnames"
import { ReactComponent as Wallet } from "assets/icons/wallet.svg"
import { ReactComponent as Money } from "assets/icons/money-send.svg"
import "./Dashboard.scss"
import { Stake } from "../Stake"
import { Withdrawal } from "../Withdrawal"
import { Referral } from "../Referral"
import { Crude } from "../Crude"
import { Levels } from "../Levels"
import { Seed } from "../Seed"
import { Yield } from "../Yield"

const tabs = ["stake", "withdraw", "Daily Profit", "referral", "seed"]

type Props = {
  approved: number
  userWithdrawal: number
  available: number
  earnings: number
  totalBonus: number
  userDeposit: number
  seedCount: number
  yieldCount: number
  totalInvested: number
}

export const Dashboard = ({
  approved,
  available,
  userWithdrawal,
  earnings,
  seedCount,
  userDeposit,
  yieldCount,
}: Props): JSX.Element => {
  const [tab, setTab] = useState("stake")

  return (
    <section id="dashboard" className={cn("dashboard")}>
      <h1 className="dashboard-title">Dashboard</h1>
      <div className="dashboard-tabs">
        {tabs.map((item, index) => (
          <button
            className={cn("dashboard-tabs-item", {
              active: tab === item,
            })}
            onClick={() => setTab(item)}
            key={index}
          >
            {item}
          </button>
        ))}
      </div>
      {tab === "stake" && <Stake approved={approved} />}
      {tab === "withdraw" && (
        <Withdrawal
          userDeposit={userDeposit}
          available={available}
          userWithdrawal={userWithdrawal}
        />
      )}
      {tab === "seed" && <Seed seedCount={seedCount} />}
      {tab === "Daily Profit" && <Yield yieldCount={yieldCount} />}
      {tab === "referral" && <Referral earnings={earnings} />}
      {/*{tab === "crude" && <Crude totalBonus={totalBonus} totalInvested={totalInvested} />}*/}
      {tab === "levels" && <Levels />}
    </section>
  )
}
