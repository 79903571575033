import { Header, Footer, Collapse } from "components"

import "./ComingSoon.scss"
import imgSrc from "assets/images/coming-soon.webp"
import { ReactComponent as Logo } from "assets/icons/mini-logo.svg"
import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import { Medias, routes } from "utils"

export const ComingSoon = (): JSX.Element => {
  const [search] = useSearchParams()

  return (
    <div className="coming-soon" style={{ backgroundImage: `url(${imgSrc})` }}>
      <Logo />
      <h1 className="coming-soon-title">Rise USDT</h1>
      <h2 className="coming-soon-subtitle">Coming Soon</h2>
      <Link className="coming-soon-link" to={`${routes.index}?${search.toString()}`}>
        Go to the home page
      </Link>
      <Medias className="coming-soon-medias" />
    </div>
  )
}
